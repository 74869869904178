import { cloneFormData, getLanguage } from './Helpers'
import { TrustedForm } from './TrustedForm'
import { Jornaya } from './Jornaya'
import { Ringba } from './Ringba'
import { Hook } from './enums/Hook'
import { ThankYouPageResource } from '../types/resources/ThankYouPageResource'

/* eslint-disable camelcase */
const trustedForm = new TrustedForm()
const ringba = new Ringba()
const jornaya = new Jornaya()

type DOMContentLoadedFunction = (metadata: Record<string, unknown>) => void

type BeforeStepSubmissionFunction = (formData: Record<string, unknown>, metadata: Record<string, unknown>) => void

type AfterEnterThankYouPageFunction = (metadata: ThankYouPageResource['data']) => void

type OnWebFormStartFunction = (metadata: Record<string, unknown>) => void

type HookFunction = DOMContentLoadedFunction
    | BeforeStepSubmissionFunction
    | AfterEnterThankYouPageFunction
    | OnWebFormStartFunction

export const HookToFunctionMap: { [ hook: string ]: HookFunction } = {
    [ Hook.DOMContentLoaded ]: domContentLoaded as DOMContentLoadedFunction,
    [ Hook.BeforeStepSubmission ]: beforeStepSubmission as BeforeStepSubmissionFunction,
    [ Hook.AfterEnterThankYouPage ]: afterEnterThankYouPage as AfterEnterThankYouPageFunction,
    [ Hook.OnWebFormStart ]: onWebFormStart as OnWebFormStartFunction,
}

export function domContentLoaded(metadata: Record<string, unknown>) {

    const pubId = getPubIdFromUrl()

    ringba.createRingbaTagScript(metadata)
    ringba.push({ visitor_id: window.__STATE.visitorId })
    ringba.push({ language: getLanguage() })
    ringba.push({ fingerprint: window.__STATE.tokens.fingerprint })
    ringba.push({ ip_address: window.__STATE.tokens.ip_address })
    ringba.push({ referer: window.__STATE.tokens.referer })
    ringba.push({ web_form_id: window.__STATE.webForm.key })
    ringba.push({ landing_page_id: window.__STATE.webForm?.landingPage?.key })
    ringba.push({ landing_page_name: window.__STATE.webForm?.landingPage?.name })

    if (window.__STATE.tokens.campaign) {

        for (const key in window.__STATE.tokens.campaign) {
            ringba.push({ [ `campaign_${ key }` ]: window.__STATE.tokens.campaign[ key ] })
        }

    }

    if (window.__STATE.tokens.landingPage) {

        for (const key in window.__STATE.tokens.landingPage) {
            ringba.push({ [ `landing_page_${ key }` ]: window.__STATE.tokens.landingPage[ key ] })
        }

    }

    /**
     * Set the approvedCreative `smid` attached to the web-form to tags when present to take
     * precedence over a manual entry set in the web-form tags.
     */
    const existingTags = window.__STATE.webForm.tags ?? {}
    const tags = window.__STATE.tokens?.smid ? { ...existingTags, smid: window.__STATE.tokens?.smid } : existingTags

    for (const key in tags) {
        ringba.push({ [ `webform_${ key }` ]: tags[ key ] })
    }

    if (pubId) {
        ringba.push({ pub_id: pubId })
    } else {

        let attempts = 50

        const interval = setInterval(function() {

            attempts--

            if (typeof dtpCallback === 'function') {

                dtpCallback(function(clickId) {

                    if (clickId) {

                        ringba.push({ pub_id: clickId })
                        clearInterval(interval)

                    }

                })

            }

            if (attempts === 0) {
                clearInterval(interval)
            }

        }, 1000)

    }

}

export function beforeStepSubmission(formData: Record<string, unknown>, metadata: Record<string, unknown>) {

    formData.language = getLanguage()

    const trustedFormUrl = trustedForm.getTrustedFormUrl()
    const pubId = getPubIdFromUrl()

    if (trustedFormUrl) {
        formData.trusted_form_url = trustedFormUrl
    }

    const clonedFormData = cloneFormData(formData)

    if (pubId) {

        formData.pub_id = pubId
        ringba.push({ pub_id: pubId })

    }

    ringba.push({ language: getLanguage() })
    ringba.pushFormData(clonedFormData)

    if (typeof dtpCallback === 'function' && pubId === null) {

        dtpCallback(function(clickId) {

            formData.pub_id = clickId
            ringba.push({ pub_id: clickId })

        })

    }

    const leadiD = jornaya.getLeadiDToken()

    if (leadiD) {
        formData.jornaya_id = leadiD
    }

    // Include Impression ID if it exists
    try {

        const storageKey = localStorage.getItem(`ringbaNumber_${ window.RINGBA_TAG }`)

        if (storageKey) {
            formData.ringba_impression_id = JSON.parse(storageKey).impressionQueryPath
        }

    } catch (error) {
        // Prevent errors from showing in console.
    }

    // Associate vertical to leads
    if (metadata.vertical) {
        formData.vertical = metadata.vertical
    }

}

export function afterEnterThankYouPage(metadata: ThankYouPageResource['data']) {

    ringba.push({
        thankYouPage: true,
        ThankYouPageId: metadata.key,
    })

    if (metadata.tags) {

        for (const key in metadata.tags) {
            ringba.push({ [ `thank_you_page_${ key }` ]: metadata.tags[ key ] })
        }

    }

}

export function onWebFormStart() {

    trustedForm.createScript()
    jornaya.createScript()

}

export function beforeChatConversationUpdated(formData: Record<string, unknown>, metadata: Record<string, unknown>): void {

    const formDataMetadata: Record<string, unknown> = {}

    /*
     * This will be called before we send the raw chat conversation to the server so that we include any
     * additional data we want to attach to the visitor answer.
     */
    formDataMetadata.language = getLanguage()

    const trustedFormUrl = trustedForm.getTrustedFormUrl()
    const pubId = getPubIdFromUrl()

    if (trustedFormUrl) {
        formDataMetadata.trusted_form_url = trustedFormUrl
    }

    if (pubId) {
        formDataMetadata.pub_id = pubId
    } else if (typeof dtpCallback === 'function') {

        dtpCallback(function(clickId) {
            formDataMetadata.pub_id = clickId
        })

    }

    // Include Impression ID if it exists
    try {

        const storageKey = localStorage.getItem(`ringbaNumber_${ window.RINGBA_TAG }`)

        if (storageKey) {
            formDataMetadata.ringba_impression_id = JSON.parse(storageKey).impressionQueryPath
        }

    } catch (error) {
        // Prevent errors from showing in console.
    }

    // Associate vertical to leads
    if (metadata.vertical) {
        formDataMetadata.vertical = metadata.vertical
    }

    formData.metadata = formDataMetadata

}

export function afterChatConversationUpdated(formData: Record<string, unknown>, latestAnswer: Record<string, unknown>) {

    /*
     * This will be called after we receive the updated conversation from the server so that we can send the correct
     * data to the Third parties. We merge the formData with the processed answer from the server.
     */
    const clonedFormData = cloneFormData({ ...formData.metadata, ...latestAnswer })
    const pubId = getPubIdFromUrl()

    if (pubId) {
        ringba.push({ pub_id: pubId })
    } else if (typeof dtpCallback === 'function') {

        dtpCallback(function(clickId) {
            ringba.push({ pub_id: clickId })
        })

    }

    ringba.push({ language: getLanguage() })
    ringba.pushFormData(clonedFormData)

}

function getPubIdFromUrl() {

    const pubId = location.search.match(/pub_id=(?<id>[\w|-]+)/)

    if (pubId && pubId.groups && pubId.groups.id) {
        return pubId.groups.id
    }

    return null

}
